<template>
  <footer class="footer">
    <div class="footer-box wrap">

      <div class="ul">
        <div
          class="li"
          v-for="(item, index) in list "
          :key="index"
        >
          <router-link :to="item.link">
            {{item.name}}
          </router-link>
        </div>
      </div>

      <div
        class="footer-info"
        v-for="(item, index) in info"
        :key="index"
      >
        <div class="footer-info-list">
          {{item.copyright}}<a class="icp" href="https://beian.miit.gov.cn/">{{item.icp}}</a>
        </div>
        <div class="footer-info-list">
          <div class="list-box">
            <span class="list-box-num"> <img src="@/assets/image/home/footer-phone.png"></span>
            <p class="list-box-title"> {{$t('phone')}} </p>
            <p class="list-box-content"> {{item.phone}} </p>
          </div>
        </div>
        <div class="footer-info-list">
          <div class="list-box">
            <span class="list-box-num"> <img src="@/assets/image/home/footer-fax.png"></span>
            <p class="list-box-title"> {{$t('fax')}} </p>
            <p class="list-box-content"> {{item.fax}} </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import gql from 'graphql-tag';

export default {
  apollo: {
    // 简单的查询，将更新 'hello' 这个 vue 属性
    list: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              list: allPrimaryLabels {
                name
                link
              }
            }
          `;
        }
        return gql`
          query {
            list: allEnPrimaryLabels {
              name
              link
            }
          }
        `;
      },
    },
    info: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              info: allFooters {
                copyright
                phone
                fax
                icp
              }
            }
          `;
        }

        return gql`
          query {
            info: allEnFooters {
              copyright
              phone
              fax
              icp
            }
          }
        `;
      },
    },
  },
  computed: {
    languageModelPrefix() {
      if (this.$i18n.locale === 'en') {
        return 'En';
      }

      return '';
    },
  },
  data() {
    return {
      list: [],
      info: [],
    };
  },
};
</script>

<style lang="scss" scoped>
// 网站底部
.footer {
  width: 100%;
  height: 330px;
  background: #000;

  .footer-box {
    padding-top: 80px !important;

    .ul {
      width: 100%;
      display: flex;
      justify-content: center;
      border-top: 1px solid #202020;
      border-bottom: 1px solid #202020;

      .li {
        width: 160px;
        text-align: center;
        height: 58px;
        line-height: 58px;

        a {
          color: #dedede;
        }
      }
    }

    .footer-info {
      margin-top: 80px;
      display: flex;

      .footer-info-list {
        width: 246px;
        color: #dedede;
        height: 40px;
        border-left: 1px solid #333;
        position: relative;

        .icp {
          margin-left: 16px;
        }

        a {
          color: #dedede;
        }

        .list-box {
          margin-left: 106px;

          .list-box-num {
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            top: -6px;
            left: 46px;
          }

          .list-box-title {
            color: #555;
            margin-bottom: 4px;
          }
        }
      }

      .footer-info-list:nth-child(1) {
        width: 706px;
        line-height: 40px;
        border: none;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    width: 1200px;
  }
}
</style>
